html {
  scroll-behavior: smooth;
}
.ant-dropdown-menu {
  background-color: rgba(1, 1, 1, 0.2) !important;
}
iframe {
  display: none;
}
.aos-init:not(.aos-animate):after {
  position: fixed;
}
section {
  overflow: hidden;
}
@font-face {
  font-family: "Shippori Mincho";
  src: url("../public/Shippori_Mincho/ShipporiMincho-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Shippori Mincho";
  src: url("../public/Shippori_Mincho/ShipporiMincho-Regular.ttf");
  font-weight: 400;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: Shippori Mincho !important;
}
